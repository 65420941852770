import React, { useEffect } from "react"
import Search from "../Components/Search"
import TableTherapist from "../Components/Tables/TableTherapist"
import data from "../Components/Data/DummyData"
import AvatarCustomers from "../Components/AvatarCustomers"
import { Link } from "react-router-dom"
import PersonSearchIcon from "@mui/icons-material/PersonSearch"
import { useState } from "react"
import clientData from "../Components/Data/ClientDetails"
import ApiRequests from "../../../api/ApiRequests"
import { useQuery } from "@tanstack/react-query"
import { useFetchBookings } from "../../../../Zustand"
import Navbar from "../Components/Navbar"
import { Typography } from "@mui/material"
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Box } from "@mui/system"

const Customers = () => {
	const { getTherapistInfo, getBookingList } = ApiRequests()
	const [events, setEvents] = useState([])
	const [filteredNames, setFilteredNames] = useState([])
	const [searchInp, setSearchInp] = useState("")

	const {
		data: bookingData,
		isError,

		isLoading,
	} = useQuery({
		queryKey: ["booking-data"],
		queryFn: () => getBookingList(),
	})

	console.log("booking data", bookingData)
	console.log("first event", events)

	useEffect(() => {
		setEvents(
			bookingData?.data?.bookings.map((event) => ({
				...event,
				id: event?.session_id,
				event_link: null,
				patient_name: event?.patient_name,
				patient_id: event?.booked_patient_id,
				event_source: "Sakina",
			}))
		)
	}, [bookingData?.data?.bookings, isLoading])

	if (isError) return <h1>Error</h1>
	if (isLoading) return <h1>Loading...</h1>

	const handleSearch = (e) => {
		setSearchInp(e);
		const filtered = uniqueUsers.filter((item) =>
			item.patient?.name?.toLowerCase().includes(e.toLowerCase())
		)
		setFilteredNames(filtered)
	}

	const uniqueUsers = events?.filter(
		(user, index, self) =>
			index === self.findIndex((u) => u.patient_id === user.patient_id)
	)

	console.log("uniqueUsers", uniqueUsers)
	if (!uniqueUsers.length) return (
		<>
			<Navbar title="Customers" />
			<Box className="fixed z-[-1] md:z-0 top-0 left-0 md:relative gap-1 md:gap-2 flex justify-center items-center min-h-full min-w-full text-gray-400">
				<ReceiptLongOutlinedIcon className="!w-14 !h-14 md:!w-20 md:!h-20"/>
				<Typography fontSize={24}>Empty list</Typography>
			</Box>
		</>
	);		
	return (
		<>
			<Navbar title="Customers" />
			<div className="m-4 md:m-10 ">
				<div className="input-group relative flex flex-nowrap items-stretch w-full md:w-[400px] mb-4">
					<input
						type="search"
						onChange={(e) => handleSearch(e.target.value)}
						className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
						placeholder="Search customer"
						aria-label="Search"
						aria-describedby="button-addon2"
					/>
					<span>
						<button
							className="btn ml-2 px-6 py-2.5 bg-blue-600 text-white text-xs   rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
							type="button"
							id="button-addon2"
						>
							<PersonSearchIcon />
						</button>
					</span>
				</div>

				<table className="w-full">
					<thead>
						<tr className="text-left">
							<th>Name</th>
							{/* <th>Gender</th> */}
						</tr>
					</thead>
					<tbody>
						{ searchInp ? 
							filteredNames.sort((a, b) => (a.patient_name > b.patient_name ? 1 : -1))
							.map((item, index) => <PatientItem item={item} index={index} type="search"/>) :
							uniqueUsers.sort((a, b) => (a.patient_name > b.patient_name ? 1 : -1))
							.map((item, index) => <PatientItem item={item} index={index} type="search" />)
						}
						{
							searchInp && !filteredNames.length ? <Typography className="!mt-3 text-gray-400 !font-semibold !text-lg">Customer not found</Typography> : null
						}
					</tbody>
				</table>
			</div>
		</>

	)
}

const PatientItem = ({item, index, type}) => {
	return (
		<tr className="border-b-[0.5px] border-[#d2d2d2]" key={`patients${index}_${type}`}>
			<Link
				key={item.patient.id}
				to={`/therapist/customers/${item.patient.id}`}
				className="flex flex-nowrap my-3 gap rounded-2xl items-center hover:bg-slate-100"
			>
				{/* <AvatarCustomers name={item.patient.name} /> */}
				<td className="font-medium text-lg cursor-pointer  text-stone-900 w-full">
					{item.patient.name} {item.patient.surname}
				</td>
			</Link>
		</tr>
	)
}

export default Customers
