import React, { useState, useEffect } from "react"
import { formatDate } from "@fullcalendar/core"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin from "@fullcalendar/interaction"
import { INITIAL_EVENTS, createEventId } from "./event-utils"
import "./index.css"
import UpdateEvent from "./UpdateEvent"
import { useTokenStore } from "../../../../../Zustand"
import { createAxios } from "../../../helpers/createAxios"
import SyncCalendar from "../../Components/SyncCalendar"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import VideoCallIcon from "@mui/icons-material/VideoCall"
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined"
import ApiRequests from "../../../../api/ApiRequests"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import LoadingPage from "../../../../../components/Cards/LoadingPage"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined"
import CancelSession from "../../Components/CancelSession"

export default function MyCalendar() {
	const [allEvents, setAllEvents] = useState([])
	const [weekendsVisible, setWeekendsVisible] = useState(true)
	const [currentEvents, setCurrentEvents] = useState([])
	const [selectedEvent, setSelectedEvent] = useState(null)
	const [updateEvent, setUpdateEvent] = useState(false)
	const { token } = useTokenStore()
	const Axios = createAxios(token?.access_token)

	const {
		getBookingList,
		cancelCalendarEvents,
		getCalendarEvents,
		getCalendarSyncedInfo,
	} = ApiRequests()

	const {
		data: calendarSyncData,
		isError: isErrorSync,
		isLoading: isLoadingSync,
	} = useQuery({
		queryKey: ["calendarSyncData"],
		queryFn: () => getCalendarEvents(),
		refetchOnWindowFocus: true,
	})

	const navigate = useNavigate()

	function extractNamesToString(participants) {
		const names = participants
			.map((participant) => participant.name)
			.filter((name) => name !== null)

		// Join the names into a single string separated by commas
		const result = names.join(" ")
		return result
	}

	useEffect(() => {
		setAllEvents(
			calendarSyncData?.data?.events
				.filter((event) => event.busy !== false)
				.filter((event) => !event.title.toLowerCase().includes("block"))
				.map((event) => ({
					//other properties can be imported using spread operator. For now we only need these properties (title, start, end)
					...event,
					start: dayjs(event?.when.start_date).local().format(),
					end: dayjs(event?.when.end_date).local().format(),
					participants: event?.participants,
					title: event?.participants.length
						? extractNamesToString(event?.participants)
						: event?.title,
					event_link: event?.conferencing?.url,
					participantsNames: extractNamesToString(event?.participants),
				}))
		)
	}, [isLoadingSync, calendarSyncData])

	// if Calendar is not synced, redirect to syncalendar page
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_ENDPOINT}/calendar/sync`,
					{
						headers: {
							"Content-Type": "application/json",
							accept: "application/json",
							authorization: "Bearer " + token.access_token,
						},
						timeout: 8000,
					}
				)
				// Process the response data here
				console.log("getCalendarSyncedInfo:", response.data)
				const isCalendarSynced = response.data.calendar_synced
				if (!isCalendarSynced) {
					navigate("/therapist/onboarding/sync-calendar")
				}
			} catch (error) {
				console.log("error getCalendarSyncedInfo", error)
			}
		}
		fetchData()
	}, [])

	console.log("allEvents", allEvents)

	const queryClient = useQueryClient()
	const {
		mutate,
		isError: isErr,
		isLoading: isLoad,
	} = useMutation({
		mutationFn: (id: string) => cancelCalendarEvents(id),
		onSuccess: () => queryClient.invalidateQueries(["calendarSyncData"]),
	})

	const handleWeekendsToggle = () => {
		setWeekendsVisible(!weekendsVisible)
	}

	const handleEventClick = (event) => {
		const handleClick = () => {
			setSelectedEvent(event)
			setUpdateEvent(true)
			return (
				<li
					key={event?.id}
					className="border rounded-md p-4 cursor-pointer"
					onClick={handleClick}
				>
					{/* Can Use DayJS to make it look pretier */}
					<b className="text-cyan-500">
						{formatDateX(event?.start)}
						<i>{event?.title}</i>
					</b>
					<br />
				</li>
			)
		}
	}

	const handleEvents = (events) => {
		setCurrentEvents(events)
	}

	const handleClose = () => {
		setUpdateEvent(false)
	}

	const renderEventContent = (eventInfo) => {
		const source = eventInfo?.event?.extendedProps?.organizer_name
		return (
			<div
				className={`${
					source === "Sakina" ? "bg-blue-400" : "bg-green-400"
				} w-full p-1 rounded-sm`}
			>
				<b>{eventInfo?.timeText}</b>
				{/* <b>{eventInfo}</b> */}

				<p className="whitespace-normal">{/* @ts-ignore */}</p>
				<p className="whitespace-normal ">{eventInfo.event?.title}</p>
			</div>
		)
	}

	const now = dayjs()

	const upcomingEvents = allEvents?.filter((event) => {
		const eventEndTime = dayjs(event.end)
		return eventEndTime.isAfter(now)
	})

	const upcomingEventsCount = allEvents?.filter((event) => {
		const eventEndTime = dayjs(event.end)
		return eventEndTime.isAfter(now)
	}).length

	const formatDateX = (dateTime) => {
		return dayjs(dateTime).format("MMMM D, h:mm A")
	}

	function renderTableRow(event) {
		const handleEventClick = () => {
			setSelectedEvent(event)
			setUpdateEvent(true)
		}

		const startTime = dayjs(event?.start).format("HH:mm")
		const endTime = dayjs(event?.end).format("HH:mm")
		const displayTitle =
			event?.title || "Please check your Google calendar for title"
		const displayDescription =
			event?.description || "Please check your Google calendar for description"

		return (
			<tr key={event?.id} className="event-row cursor-pointer border-b">
				<td className="align-middle">
					<CalendarMonthOutlinedIcon
						fontSize="small"
						className="text-inherit align-bottom mr-2"
					/>
					{dayjs(event?.start).format("DD MMM YY")}
				</td>
				<td className="align-middle">
					<ScheduleOutlinedIcon
						fontSize="small"
						className="text-inherit align-bottom mr-2"
					/>
					{startTime} - {endTime}
				</td>
				<td onClick={handleEventClick}>
					{event?.title || displayTitle || displayDescription}
				</td>
				<td>
					{" "}
					<div
						className={`opacity-70 text-white text-xs font-bold p-2 rounded w-40 ${
							event?.sakina_booking_type === "paid"
								? "bg-blue-500"
								: event?.sakina_booking_type === "free_trial"
								? "bg-yellow-500"
								: "bg-green-500"
						}`}
					>
						{
							event?.sakina_booking_type === "paid"
								? "Sakina Session"
								: event?.sakina_booking_type === "free_trial"
								? "Sakina Free Trial"
								: "Other Calendar Event" // Handle null case here
						}
					</div>
				</td>
				<td>
					{event?.event_link ? (
						<button
							className="btn-call bg-violet-500 w-40"
							onClick={() => window.open(`${event?.event_link}`, "_blank")}
						>
							Start the call <VideoCallIcon />
						</button>
					) : (
						<button className="btn-call bg-gray-300 w-40 " disabled>
							No Link Call <LinkOffOutlinedIcon />
						</button>
					)}
				</td>
				<td>
					{" "}
					<CancelSession
						isLoad={isLoad}
						mutate={mutate}
						selectedEvent={selectedEvent}
					/>{" "}
				</td>
			</tr>
		)
	}

	if (isErr || isErrorSync) return <h1>Error</h1>
	if (isLoadingSync) return <LoadingPage />

	return (
		<div className="flex flex-col over">
			<div className="md:w-11/12 md:m-10">
				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					headerToolbar={{
						left: "prev next today",
						center: "title",
						right: "dayGridMonth,timeGridWeek,timeGridDay",
					}}
					eventColor="rgba(214, 246, 255, 0.9)"
					initialView="dayGridMonth"
					// editable={true}
					selectable={true}
					selectMirror={true}
					dayMaxEvents={true}
					weekends={weekendsVisible}
					initialEvents={INITIAL_EVENTS}
					// select={handleDateSelect}
					eventContent={renderEventContent}
					eventClick={handleEventClick}
					// eventsSet={handleEvents}
					eventRemove={function () {}}
					displayEventTime={true}
					events={allEvents}
					// eventsClick={(event) => console.log(event)}
					// eventOrderStrict={true}
					//change it to conditional formating - past event's different colour, upcomings events different colour, googleevents different color
					timeZone="local"
					eventDisplay="list-item"
					nowIndicator={true}
					navLinks={true}
					eventTextColor=""
					// allDaySlot={true}
					slotEventOverlap={false}
					firstDay={1}
					slotDuration="00:15:00"
					eventTimeFormat={{
						hour: "numeric",
						minute: "2-digit",
						meridiem: false,
					}}
					businessHours={[
						{
							daysOfWeek: [1, 2, 3, 4, 5], // Monday-Friday
							startTime: "09:00",
							endTime: "18:00",
						},
					]}
				/>
			</div>
			<div className="md:w-11/12 md:mb-10 mt-4 md:m-0 md:m-auto">
				<h2 className="md:p-4 mb-2 md:m-0 text-lg font-semibold">
					Upcoming Events ({upcomingEventsCount})
				</h2>
				<div className="bg-white overflow-auto">
					<table className="w-full text-sm text-left border text-gray-500 border-separate border-spacing-x-2">
						<thead className="text-xs py-5 text-gray-700 uppercase bg-gray-50">
							<tr>
								<th className="min-w-[95px] md:min-w-0">Date</th>
								<th className="min-w-[115px] md:min-w-0">Time</th>
								<th className="min-w-[120px] md:min-w-0">Description</th>
								<th>Type</th>
								<th>Link</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>{upcomingEvents?.map(renderTableRow)}</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
