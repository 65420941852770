// @ts-nocheck
import React, { useState } from "react"
import "./App.css"
import "./styles.css"
import AuthContainer from "./views/auth/User/AuthContainer"
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import LoginWithPhone from "./views/auth/User/SignInPhone"
import Login from "./views/auth/User/SignIn"
import SignUp from "./views/auth/User/SignUp"
import Profile from "./views/sakinah/User/Profile"
import Preferences from "./views/sakinah/User/MyPreferences"
import MyDashboard from "./views/sakinah/User/MyDashboard"
import MySessions from "./views/sakinah/User/MySessions"
import MyTherapists from "./views/sakinah/User/MyTherapists"
import MySettings from "./views/sakinah/User/MySettings"
import ProtectedRoute from "./views/auth/ProtectedRoutes/ProtectedRoute"
import UserInfo from "./views/sakinah/User/UserInfo"
import ForgotPassword from "./views/auth/User/ForgotPassword"
import MyPreferences from "./views/sakinah/User/MyPreferences"
import { useStoreUser, useTokenStore } from "./Zustand"
import LayoutTherapist from "./views/sakinah/Therapist/pages/LayoutTherapist"
import Calendar from "./views/sakinah/Therapist/pages/Calender/Calendar"
import Onboarding from "./views/sakinah/Therapist/Onboarding/Onboarding"
import AuthTherapist from "./views/auth/Therapist/AuthTherapist"
import LoginTherapist from "./views/auth/Therapist/SignInTherapist"
import OnboardingLayout from "./views/sakinah/Therapist/Onboarding/OnboardingLayout"
import SignUpTherapist from "./views/auth/Therapist/SignUpTherapist"
import ProtectedRouteTherapist from './views/auth/ProtectedRoutes/ProtectedRouteTherapist';
import PaymentsTherapist from "./views/sakinah/Therapist/pages/PaymentsTherapist"
import SettingsTherapist from "./views/sakinah/Therapist/pages/SettingsTherapist"
import CustomerProfile from "./views/sakinah/Therapist/pages/CustomerProfile"
import Onboarding2 from "./views/sakinah/Therapist/Onboarding/Onboarding2"
import VerifyEmail from "./views/auth/User/VerifyEmail"
import VerifyEmailTherapist from "./views/auth/Therapist/VerifyEmailTherapist"
import { createAxios } from "./views/sakinah/helpers/createAxios"
import SyncCalendar from "./views/sakinah/Therapist/Components/SyncCalendar"
import NysonCallback from "./views/sakinah/Therapist/pages/Calender/NysonCallback"
import TherapistInfoPage from "./views/sakinah/User/Therapists/TherapistInfoPage"
import SuccessEvent from "./views/sakinah/User/SuccessEvent"
import ProtectedRouteUserOnboarding from "./views/auth/ProtectedRoutes/ProtectedRouteUserOnboarding"
import LoadingPage from "./components/Cards/LoadingPage"
import SyncCalendarPage from "./views/sakinah/Therapist/Onboarding/SyncCalendar"
import Customers from "./views/sakinah/Therapist/pages/Customers"
import SakinaTag from "./views/sakinah/Therapist/pages/SakinaTag/Main"
import UserSetNewPassword from "./views/auth/User/UserSetNewPassword"
import TherapistSetNewPassword from "./views/auth/Therapist/TherapistSetNewPassword"
import OnProccess from "./views/sakinah/Therapist/Onboarding/OnProccess"
import JsonDisplayComponent from "./views/sakinah/helpers/jsonDisplay"
import ProtectedRouteTherapistOnboarding from "./views/auth/ProtectedRoutes/ProtectedRouteTherapistOnboarding"
import NotFound from "./views/sakinah/helpers/NotFound"
import ExternalBook from "./views/sakinah/Therapist/pages/SakinaTag/ExternalBook"

function App() {
	const { userInfo, setUserInfo } = useStoreUser()
	const { token } = useTokenStore()
	const location = useLocation()
	const [loading, setLoading] = useState(false)

	// Fetch user info
	async function getCurrentUser() {
		setLoading(true)
		const Axios = createAxios(token?.access_token)
		if (!token?.access_token)  {
			setLoading(false)
			return
		}
		if (location?.pathname.includes('nylas-callback') || location?.pathname.includes('therapist') || (userInfo?.data && "therapist" in userInfo.data)) {
			try {
				const data = await Axios.get(`/therapist`)
				setUserInfo(data)
				setLoading(false)
			} catch (error) {
				console.log("error app :>> ", error)
				setLoading(false)
			}
			return
		}
		try {
			const data = await Axios.get(`/patient`)
			setUserInfo(data)
			setLoading(false)
		} catch (error) {
			console.log("error app :>> ", error)
			setLoading(false)
		}
	}

	window.addEventListener('load', () => {
		const root = document.getElementById('root');
		if (root.nextElementSibling) {
			root.parentNode.removeChild(root.nextElementSibling);
		}
	});		
	React.useEffect(() => {
		location.pathname.includes("onboarding/sync-calendar") && getCurrentUser()
	}, [])

	console.log("token :>> ", token)
	React.useEffect(() => {
		if (token?.access_token) getCurrentUser();
	}, [token?.access_token])

	if (loading) return <LoadingPage />
	return (
		<Routes>
			<Route path="/patient/verify-email" element={<VerifyEmail />} />
			<Route path="/patient/reset-password" element={<UserSetNewPassword />} />
			<Route path="/sync" element={<SyncCalendar />} />
			<Route path="/nylas-callback" element={<NysonCallback />} />
			<Route
				path="/.well-known/microsoft-identity-association.json"
				element={<JsonDisplayComponent />}
			/>
			<Route path="/.well-known/apple-developer-merchantid-domain-association" />

			<Route path="/auth" element={<AuthContainer />}>
				<Route path="login" element={<Login />} />
				<Route path="login-phone" element={<LoginWithPhone />} />
				<Route path="register" element={<SignUp />} />
				<Route path="forgot-password/:type" element={<ForgotPassword />} />
			</Route>

			<Route
				element={<ProtectedRouteUserOnboarding token={token} loading={loading} />}
			>
				<Route path="/user-info" element={<UserInfo />} />
				{/* <Route path="/user-preferences" element={<MyPreferences />} /> */}
			</Route>

			<Route
				element={
					<ProtectedRoute
						user={userInfo}
						setUserInfo={setUserInfo}
						token={token}
						loading={loading}
					/>
				}
			>
				<Route path="/" element={<Profile />}>
					<Route index element={<MyDashboard />} />
					<Route
						path="/calendar/event/:event_id/success"
						element={<SuccessEvent />}
					/>
					{/* <Route path="preferences" element={<Preferences />} /> */}
					<Route path="sessions" element={<MySessions />} />
					<Route path="therapist-list" element={<MyTherapists />} />
					<Route path="therapist-profile/:id" element={<TherapistInfoPage />} />
					<Route path="settings" element={<MySettings />} />
				</Route>
			</Route>

			{/* Therapist */}
			<Route path="/auth/therapist" element={<AuthTherapist />}>
				<Route path="signin" element={<LoginTherapist />} />
				<Route path="signup" element={<SignUpTherapist />} />
			</Route>

			<Route path="/therapist/verify-email" element={<VerifyEmailTherapist />} />
			<Route path="/therapist/reset-password" element={<TherapistSetNewPassword />} />
			<Route
				element={
					<ProtectedRouteTherapistOnboarding
						token={token}
						loading={loading}
					/>
				}
			>

				<Route path="/therapist/onboarding/" element={<OnboardingLayout />}>
					<Route
						index
						element={
							<Onboarding
								hidden="false"
								prevPage=""
								nextPage="/therapist/onboarding/2"
								text="Next"
							/>
						}
					/>
					<Route
						path="2"
						element={
							<Onboarding2
								hidden={false}
								prevPage="/therapist/onboarding/"
								nextPage=""
								text="Submit"
							/>
						}
					/>
					<Route path="sync-calendar" element={<SyncCalendarPage />} />
				</Route>
			</Route>

			<Route
				element={
					<ProtectedRouteTherapist
						user={userInfo}
						setUserInfo={setUserInfo}
						token={token}
						loading={loading}
					/>
				}
			>
				<Route path="therapist" element={<LayoutTherapist />}>
					{/* <Route index element={<DashboardTherapist />} /> */}
					<Route index element={<Calendar />} />
					<Route path="customers" element={<Customers />} />
					<Route path="customers/:id" element={<CustomerProfile />} />
					<Route path="payments" element={<PaymentsTherapist />} />
					<Route path="therapist-settings" element={<SettingsTherapist />} />
				</Route>
			</Route>

			<Route path="/:tag">
				<Route index element={<SakinaTag />} />
				<Route path="book" element={<ExternalBook />} />
			</Route>
			<Route path="/onproccess" element={<OnProccess />} />
			<Route path="/*" element={<NotFound />} />
			{/* </Route> */}
			{/* </Route> */}
		</Routes>
	)
}

export default App
